/* WHITE */
.white_text_400_20px_32px {
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #ffffff;
}
.white_text_400_16px_24px {
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
/* NEUTRAL TEXT */
.neutral_text_400_12px_16px {
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #0f172a;
}
.neutral_text_400_14px_16px {
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #0f172a;
}
.neutral_text_400_14px_24px {
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #0f172a;
}
.neutral_text_400_16px_24px {
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #0f172a;
}
.neutral_text_400_18px_24px {
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #0f172a;
}
.neutral_text_600_16px_20px {
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #0f172a;
}
.neutral_text_600_16px_24px {
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #0f172a;
}
.neutral_text_600_20px_24px {
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #0f172a;
}
.neutral_text_600_24px_30px {
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #0f172a;
}
.neutral_text_600_26px_32px {
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  color: #0f172a;
}
.neutral_text_600_32px_40px {
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: #0f172a;
}
.neutral_text_700_16px_20px {
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #0f172a;
}
.neutral_text_700_16px_24px {
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #0f172a;
}
.neutral_text_700_26px_32px {
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  color: #0f172a;
}
/* NEUTRAL TEXT WEAK */
.neutral_text_weak_400_12px_16px {
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #475569;
}
.neutral_text_weak_400_14px_16px {
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #475569;
}
.neutral_text_weak_600_14px_16px {
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #475569;
}
.neutral_text_weak_400_16px_24px {
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #475569;
}
.neutral_text_weak_400_16px_20px {
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #475569;
}
.neutral_text_weak_600_16px_24px {
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #475569;
}
.neutral_text_weak_700_16px_20px {
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #475569;
}
.neutral_text_weak_700_26px_32px {
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  color: #475569;
}
/* PRIMARY TEXT */
.primary_text_400_16px_24px {
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2563eb;
}
.primary_text_600_16px_24px {
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #2563eb;
}
.primary_text_600_14px_24px {
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #2563eb;
}
/* PRIMARY TEXT STRONG */
.primary_text_strong_600_14px_24px {
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #1e3a8a;
}
.primary_text_strong_600_18px_24px {
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #1e3a8a;
}
.primary_text_strong_600_20px_24px {
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #1e3a8a;
}
/* DANGER TEXT */
.danger_text_400_16px_24px {
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ba0517;
}
.danger_text_400_16px_24px {
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ba0517;
}

/* SUCCESS TEXT */
.success_text_400_16px_24px {
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2e844a;
}

.top_5_text_400_20px_32px {
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #ff0000;
  background-color: white;
}
/* WARNING Text */
.warning_text_400_16px_24px {
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #C2410C;
}